import { notification } from "antd";
const locale = "pt-br";

class Utils {
  /**
   * Fast message to show
   * @param {String} value
   */
  static currencyFormatter = (selectedCurrOpt) => (value) => {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: "BRAZIL::BRL".split("::")[1],
    }).format(value);
  };

  static formatterDecimal = (selectedCurrOpt) => (value) => {
    return new Intl.NumberFormat(locale, {
      style: "decimal",
    }).format(value);
  };
  /**
   * Fast message to show
   * @param {String} val
   */
  static currencyParser = (val) => {
    try {
      if (typeof val === "string" && !val.length) {
        val = "0.0";
      }

      var group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, "");
      var decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, "");
      var reversedVal = val.replace(new RegExp("\\" + group, "g"), "");

      reversedVal = reversedVal.replace(new RegExp("\\" + decimal, "g"), ".");

      reversedVal = reversedVal.replace(/[^0-9.]/g, "");

      const digitsAfterDecimalCount = (reversedVal.split(".")[1] || []).length;
      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal = reversedVal * Math.pow(10, digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Fast message to show
   * @param {String} value
   */
  static convertCurrency(value) {
    if (!value) return "R$ ---";
    const format = typeof value === "string" ? +value : value;
    const money = format.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return money;
  }

  /**
   * Fast message to show
   * @param {String} value
   */
  static initialNames(value) {
    let name = value;
    let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

    let initials = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
    ).toUpperCase();

    return initials;
  }

  /**
   * Fast message to show
   * @param {String} email
   */
  static validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
    return re.test(email);
  }

  /**
   * Fast message to show
   * @param {String} type
   * @param {String} title
   * @param {String} message
   */
  static feedback(type, title, message) {
    notification[type]({
      message: title,
      description: message,
    });
  }

  /**
   * Validate perfil to show components and enable functionalities in the screen
   * @param {Number} roles
   * @param {Array} perfil
   * @param {String} type
   * @return {Boolean} show
   */
  static validationPerfil(roles, perfil, type, debug = false, func = "") {
    if (debug) console.log("------------------------------ FUNCAO", func);
    if (debug)
      console.log(
        "validationPerfil >>> ",
        roles,
        `perfil: ${perfil}`,
        `type: ${type}`,
        `test: ${debug}`
      );
    let result;
    if (roles === 1 || roles === 3) {
      result = true;
    } else {
      if (debug)
        console.log(
          "validationPerfil ??? ",
          perfil.find((x) => x === roles)
        );
      result = perfil.find((x) => x === roles) ? true : false;
    }
    if (debug) console.log("result === ", result);
    if (debug) console.log("------------------------------");
    if (debug) console.log("------------------------------");
    return result;
  }

  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);

    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }

    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }

    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }

    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }

    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static formatNum(value) {
    let num = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num;
  }

  static sliceString(string, count = 50) {
    if (!string) return "";
    return string.slice(0, count) + (string.length > count ? "..." : "");
  }

  static capitalizeString = (str) => {
    const lowerStr = str.toLowerCase();
    return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
  };

  static replaceStringUnderScoreForSpace = (str) => {
    return str.replace(/_/g, " ");
  };

  static switchDotsAndCommas(s) {
    function switcher(match) {
      return match == "," ? "." : ",";
    }
    return s.replaceAll(/\.|\,/g, switcher);
  }

  static formattedDate = (date) => {
    const dt = new Date(date);
    const day = dt.getUTCDate() <= 9 ? "0" + dt.getUTCDate() : dt.getUTCDate();
    const month =
      dt.getUTCMonth() + 1 <= 9
        ? "0" + (dt.getUTCMonth() + 1)
        : dt.getUTCMonth() + 1;
    const year = dt.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  static convertDateToIso = (dateString) => {
    const parts = dateString.split("/");
    const year = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10) - 1;
    const day = parseInt(parts[0], 10);

    const date = new Date(year, month, day);

    return date.toISOString();
  };

  static getCurrentDate = () => {
    const date = new Date();

    return date;
  };

  static checkTheSameDate = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    if (d1.getTime() === d2.getTime()) {
      return true;
    }

    if (d1.getFullYear() !== d2.getFullYear()) {
      return false;
    }

    if (d1.getMonth() !== d2.getMonth()) {
      return false;
    }

    if (d1.getDate() !== d2.getDate()) {
      return false;
    }

    return true;
  };

  static convertDateToYYYY_MM_DD = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  static formatCurrency = (input) => {
    if (!input) return;
    if (typeof input === "number") {
      return input;
    }
    let cleaned = input.replace("R$", "").trim();

    cleaned = cleaned.replace(".", "")?.replace(",", ".");
    return parseFloat(cleaned);
  };
  static calculateRemainingValue = (
    valorAberto,
    multa = 0,
    juros = 0,
    outros = 0,
    descontos = 0
  ) => {
    const convertToNumber = (value) =>
      typeof value === "string"
        ? parseFloat(value.replace("R$", "").replace(".", "").replace(",", "."))
        : value;

    const totalSum = convertToNumber(valorAberto) + convertToNumber(descontos);
    const total =
      totalSum -
      (convertToNumber(multa) +
        convertToNumber(juros) +
        convertToNumber(outros));

    if (total >= 0) {
      const formattedTotal = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(total);
      return formattedTotal;
    } else {
      return -1;
    }
  };
}

export default Utils;
